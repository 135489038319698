import React from "react";
import Lottie from "react-lottie";

function LogoAnimation(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: props.logoColor,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      height={props.height}
      width={props.width}
    />
  );
}

export default LogoAnimation;
