import React, { useState, useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import emailjs from "emailjs-com";

function Contact() {
  useEffect(() => {
    document.title = "TilleyTempo | Contact";
  });

  ReactPixel.trackCustom("About", { Page: "Contact" });

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [mailSuccess, setMailSuccess] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();

    if (name === "" || email === "" || message === "") {
      setValidationError(true);
    } else {
      setMailError(false);
      setValidationError(false);
      submitForm();
    }
  }

  function submitForm() {
    setIsLoading(true);
    const {
      REACT_APP_EMAILJS_TEMPLATEID: templateId,
      REACT_APP_EMAILJS_USERID: userId,
    } = process.env;

    emailjs
      .send(
        "gmail", // default email provider in your EmailJS account
        templateId,
        {
          name: name,
          email: email,
          message: message,
        },
        userId
      )
      .then((res) => {
        setName("");
        setEmail("");
        setMessage("");
        setMailSuccess(true);
        setIsLoading(false);
      })
      // Handle errors here however you like, or use a React error boundary
      .catch((err) => {
        setMailError(true);
        setIsLoading(false);
      });
  }

  return (
    <div className="container">
      <div className="row">
        <h2>
          Get In <span>Touch</span> With Leigh!
        </h2>
      </div>
      <div className="row">
        <div className="col-12 col-lg-6 px-0 px-lg-5">
          <div className="row">
            <div className="col-12">How may I play for you ?</div>
            <div className="col-12">
              fill out this form I'll contact back shortly.
            </div>
            <div className="col-12">
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                {/* Hidden Required Fields */}
                <input type="hidden" name="project_name" value="TilleyTempo" />
                <input
                  type="hidden"
                  name="admin_email"
                  value="mail@tilleytempo.com"
                />
                <input type="hidden" name="form_subject" value="Form" />
                {/* /Hidden Required Fields */}
                <div id="winError"></div>
                <input
                  className="form-control"
                  type="text"
                  id="first-name"
                  name="name"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  placeholder="Type your name"
                />
                <input
                  className="form-control mt-2"
                  type="email"
                  id="email"
                  name="Email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder="Type your email"
                />
                <textarea
                  className="form-control mt-2"
                  name="text_comment"
                  id="text_comment"
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                  placeholder="Type your messages"
                ></textarea>

                <button
                  type="submit"
                  name="done"
                  className="mt-2 btn_submit_default"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span
                      className="spinner-border spinner-border-sm mr-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    ""
                  )}
                  Send
                </button>
                {mailSuccess ? (
                  <div className="alert alert-success mt-3 p-1" role="alert">
                    Mail has been sent.
                  </div>
                ) : (
                  ""
                )}
                {mailError ? (
                  <div className="alert alert-danger mt-3 p-1" role="alert">
                    Something went wrong, Try again later.
                  </div>
                ) : (
                  ""
                )}
                {validationError ? (
                  <div className="alert alert-danger mt-3 p-1" role="alert">
                    Please fill in all details.
                  </div>
                ) : (
                  ""
                )}
              </form>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 px-0 px-lg-5 mt-4 mt-lg-0">
          <div className="row">
            <div className="col-12">
              Subscribe now to keep up to date with the news!
            </div>
            <div className="col-12">subscribe to get updates.</div>
            <div className="col-12">
              <form
                action="https://tilleytempi.us17.list-manage.com/subscribe/post?u=c30c123eadd4e26a8ec495d6c&amp;id=b66a1a9ba7"
                method="POST"
                noValidate
              >
                {/* Hidden Required Fields */}
                <input
                  type="hidden"
                  name="u"
                  value="b_b3f8f5d984f8b69f7aa4149a7_c09431907c"
                />
                <input type="hidden" name="id" value="mc-embedded-subscribe" />
                {/* /Hidden Required Fields */}
                <div id="winError"></div>
                <input
                  type="email"
                  name="EMAIL"
                  id="MERGE0"
                  autoCapitalize="off"
                  autoCorrect="off"
                  placeholder="Type your email"
                  className="form-control"
                />
                <input
                  type="text"
                  name="FNAME"
                  id="MERGE1"
                  placeholder="Type your first name"
                  className="form-control mt-2"
                />
                <input
                  type="text"
                  name="LNAME"
                  id="MERGE2"
                  placeholder="Type your last name"
                  className="form-control mt-2"
                />

                <button
                  type="submit"
                  className="btn_submit_default mt-2"
                  name="subscribe"
                >
                  <i className="fas fa-plus"></i>
                  <span> Subscribe</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <h3>Let’s work together...What sort of party shall we create?</h3>
      </div>
      <div className="row">
        <div className="col-12">Get in touch now to book...</div>
        <div className="col-12 mt-3">
          <p>Location : London, UK</p>
          <p>Email : mail@tilleytempo.com</p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
