import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
//common components
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";

//page components
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Music from "./components/pages/Music";
import Video from "./components/pages/Video";
import GalleryList from "./components/pages/Gallery";
import Shop from "./components/pages/Shop";
import Contact from "./components/pages/Contact";
import ReactPixel from "react-facebook-pixel";

//sound
import pageLoadSound from "../src/assets/sounds/tt_website_onload.mp3";

//icons
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTwitter,
  faInstagram,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
library.add(faTwitter, faInstagram, faFacebook);

function App() {
  useEffect(() => {
    // $(document).ready(function () {
    //   $(".navbar-collapse a").click(function () {
    //     $(".navbar-collapse").collapse("hide");
    //   });
    // });
  }, []);
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };
  ReactPixel.init("584416218889341", null, options);

  return (
    <BrowserRouter>
      <Header />
      <main role="main" className="container my-auto">
        <Routes>
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/shop" element={<Shop />} />
          <Route exact path="/gallery" element={<GalleryList />} />
          <Route exact path="/video" element={<Video />} />
          <Route exact path="/music" element={<Music />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/" element={<Home />} />
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
