import React, { useEffect } from 'react';
import ReactPixel from "react-facebook-pixel";

function About() {
    useEffect(() => {
        document.title = 'TilleyTempo | About';
    });
    ReactPixel.trackCustom( 'About', {Page:'About'});

    return (
        <div className="row">
            <div className="col-12 my-4">
                <div className="about-content">
                <div className="">
                    <p>
                        I was born in London in 1975 and raised in London and the South East.
                    </p>

                    <p>
                        When I was a small boy I made all of my own sound effects, I guess you could say a bit like the guy in those 80s comedy 'Police Academy' films as it was just me using my mouth!!!
                    </p>

                    <p>
                        The first records I remember owning were the first Adam Ant album, the soundtrack to 'Fame' and a Shakin' Stevens album!!?
                    </p>

                    <p>
                        After that I often heard music from my parents such as Pink Floyd and more rock-based stuff and then in the late 80s I discovered pirate radio stations and moved more towards electronic and dance music....
                    </p>

                    <p>
                        The songs that really caught my attention in the mainstream UK charts were always electronic and/or dance based. Stuff like Snap's 'The Power' (which I first heard on pirate station), Technotronic's 'Pump Up The Jam', Bassomatic's 'Fascinating Rhythm' and Adamski's 'Killer' resonated with me and I began to spend more time listening to the pirate stations than anything else. 'CentreForce FM', 'Sunrise FM', 'Fantasy FM', Kiss FM (even before it got its licence) and Girls FM to name but a few! Listening to more underground and pirate stations led me to discover the early creators of acid house, house and dance music in general!
                    </p>

                    <p>
                        I would fix a long aerial cable across the wall and linked to a telescopic aerial that I could angle out of my bedroom window! I still have some of these pirate shows on audio cassette too! Initially I was a bit young for raves and parties but eventually I went to some. At first, I used to go to house music parties at clubs like Ministry of Sound, Leisure Lounge, Cafe de Paris, Legends, The Satellite Club, Camden Palace, Bar Rumba. People dressed up and the nights were amazing fun full of classic songs (you may even hear some of the vinyl in my 'Classic House' vinyl mix series!). In November 1995, fireworks night to be precise, I attended 'World Dance' at Lydd Airport in the UK with a group of friends. I didn't really know much about jungle or drum & bass at that point, but the house arena was not that exciting. The main arena on the other hand was electrifying! I vividly remember hearing DJ Hype play, and rewind, what I would later find out was Ray Keith's 'Terrorist' as the place exploded with energy!
                    </p>

                    <p>
                        My next encounter with this type of music was Logical Progression at The Ministry of Sound on a Thursday night. Again, it was exhilarating and quite different to the house music nights I had been to. The house nights sometimes felt as if people were there to be seen whereas, like the World Dance experience, people didn't seem to care how they were dressed but they were there for the music. LTJ Bukem and crew played beautiful music as MC Conrad riffed across the soaring pads, strings and hectic breakbeats. This led my friend and I to try out Metalheadz on a Saturday night at Leisure Lounge. This was an electric night! The following week we went to the Sunday Sessions at The Blue Note, Hoxton Square, and became members!
                    </p>

                    <p>
                        The Sunday Sessions were an amazing experience with people who were really into their music knowing that we were hearing music that had usually been made that week, with producers all competing in a friendly way to push production and the sound forwards. Most of the producers would usually be standing around us on the dancefloor in front of the massive Eskimo Noise speakers!
                        I ended up in software development as my main day time career whilst forming a house band, Tilley & Killawattz, sometime around 2005. I Learned lots about music production doing this project and after suffering a freak blood clot that caused 6 full blown strokes back in 2013 I took some time to recover but all of my creative, artistic and musical concepts came to the fore!
                        I still write software but I now make sure that my artistic and creative side is happy making and producing either graphics, art, design and of course music!
                    </p>

                    <p>
                        Over on the audio page you will find mixes ranging from balearic to chillout to house to D&B! Music is a timeless dimension and there should be no boundaries or pigeon-holing of music; it can transport people to any moment in time in an instant!
                    </p>

                    <p>
                        At some point soon you will also see new song productions appearing too...
                    </p>
                </div>
                </div>
            </div>
        </div>
    );
}

export default About;