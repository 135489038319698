import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

import useSound from 'use-sound';
import booking from '../../assets/sounds/tt_booking_click.mp3';
import ReactPixel from 'react-facebook-pixel';

function Home() {

    const [playBooking] = useSound(booking);

    useEffect(() => {
        document.title = 'TilleyTempo | Home';
    });

    ReactPixel.trackCustom('Home', { Page: 'Home' });

    return (
        <div className="row">
            <div className="col-12 col-lg-9 text-center">
                <img src={require('../../assets/images/Home_image_fx.png')} alt="Leigh Tilley" />
            </div>
            <div className="col-12 col-lg-3 my-auto d-flex justify-content-center">
                <Link onClick={playBooking} to="/contact">
                    <button className="home-btn mb-4">Bookings</button>
                </Link>
            </div>
        </div>
    );
}

export default Home;