import React, { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";

function About() {
  useEffect(() => {
    document.title = "TilleyTempo | Music";
  });

  ReactPixel.trackCustom("About", { Page: "Music" });

  return (
    <div className="row my-4">
      <div className="col-12">
        <h4>Modern House</h4>
      </div>
    <div className="col-12">
        <iframe
            width="100%"
            height="60"
            src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FTilleyTempo%2Ftilleytempo-do-you-know-who-you-are-vol1%2F"
            frameBorder="0"
        ></iframe>
    </div>
      <div className="col-12">
        <iframe
          width="100%"
          height="60"
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FTilleyTempo%2Ftilleytempo-do-you-know-who-you-are-vol2%2F"
          frameBorder="0"
        ></iframe>
      </div>
        <div className="col-12">
            <iframe
                width="100%"
                height="60"
                src="https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FTilleyTempo%2Ftilleytempo-do-you-know-who-you-are-vol3%2F"
                frameBorder="0">

            </iframe>
        </div>
      <div className="col-12">
        <h4>Modern D&B</h4>
      </div>
      <div className="col-12">
        <iframe
          width="100%"
          height="60"
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FTilleyTempo%2Ftilleytempo-in-too-deep-vol1%2F"
          frameBorder="0"
        ></iframe>
      </div>
      <div className="col-12">
        <iframe
          width="100%"
          height="60"
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FTilleyTempo%2Ftilleytempo-in-too-deep-vol2%2F"
          frameBorder="0"
        ></iframe>
      </div>
      <div className="col-12">
        <iframe width="100%" height="60"
                src="https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FTilleyTempo%2Ftilleytempo-in-too-deep-vol3%2F"
                frameBorder="0"></iframe>
      </div>
      <div className="col-12">
        <iframe
          width="100%"
          height="60"
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FTilleyTempo%2Ftilleytempo-metalheadz-mix-april-2020%2F"
          frameBorder="0"
        ></iframe>
      </div>
      <div className="col-12">
        <h4>Classic 80s 90s 2000s into 2023 dance and house Fun ShowReel</h4>
      </div>
      <div className="col-12">
        <iframe
          width="100%"
          height="60"
          src="https://soundcloud.com/tilleytempo/classic-80s-90s-2000s-into-2023-dance-and-house-showreel-17-july-2023?si=eddee065788747708087ff4601d34dac&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"
          frameBorder="0"
        ></iframe>
      </div>
      <div className="col-12">
        <h4>Classic Vinyl House</h4>
      </div>
      <div className="col-12">
        <iframe
          width="100%"
          height="60"
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FTilleyTempo%2Ftilleytempo-presents-classic-house-vol1%2F"
          frameBorder="0"
        ></iframe>
        <iframe
          width="100%"
          height="60"
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FTilleyTempo%2Ftilleytempo-presents-classic-house-vol-2%2F"
          frameBorder="0"
        ></iframe>
      </div>

      <div className="col-12">
        <h4>Chillout</h4>
      </div>
      <div className="col-12">
        <iframe
          width="100%"
          height="60"
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FTilleyTempo%2Ftilleytempo-chillout-vol-1%2F"
          frameBorder="0"
        ></iframe>
        <iframe
          width="100%"
          height="60"
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FTilleyTempo%2Ftilleytempo-chillout-vol-2%2F"
          frameBorder="0"
        ></iframe>
      </div>

      <div className="col-12">
        <h4>Balearic mixes</h4>
      </div>
      <div className="col-12">
        <iframe
          width="100%"
          height="60"
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FTilleyTempo%2Ftilleytempo-presents-balearic-dream-1%2F"
          frameBorder="0"
        ></iframe>
        <iframe
          width="100%"
          height="60"
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FTilleyTempo%2Ftilleytempo-presents-balearic-dream-2%2F"
          frameBorder="0"
        ></iframe>
        <iframe
          width="100%"
          height="60"
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FTilleyTempo%2Ftilleytempo-presents-balearic-dream-3%2F"
          frameBorder="0"
        ></iframe>
          <iframe width="100%" height="60"
                  src="https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FTilleyTempo%2Ftilleytempo-presents-balearic-dream-4%2F"
                  frameBorder="0"></iframe>
      </div>

      <div className="col-12">
        <h4>Classic Golden Era Vinyl D&B</h4>
      </div>
      <div className="col-12">
        <iframe
          width="100%"
          height="60"
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FTilleyTempo%2Ftilleytempo-ah-those-sunday-nights-at-the-blue-note-vol-1%2F"
          frameBorder="0"
        ></iframe>
        <iframe
          width="100%"
          height="60"
          src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2FTilleyTempo%2Fah-hose-sunday-nights-at-the-blue-note-vol2%2F"
          frameBorder="0"
        ></iframe>
      </div>
    </div>
  );
}

export default About;
