import React, { useEffect } from 'react';
import ReactPixel from "react-facebook-pixel";

function Home() {
    useEffect(() => {
        document.title = 'TilleyTempo | Shop';
    });

    ReactPixel.trackCustom( 'About', {Page:'Shop'});

    return (
        <div className="container-fluid">
            <div className="row mb-5">
                <div className="col-12 col-lg-6">
                    <h1 className="shop-heading">Merch</h1>
                    <div className="row p-4">
                        <div className="col-12">
                            <img className="w-100" src={require('../../assets/images/ShopTeaser.PNG')} alt="Leigh Tilley" />
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 m-auto d-flex justify-content-center pt-5">
                    <button className="shop-btn" onClick={() => {
                        window.open('https://tilleytees.com/collection/tilleytempo-music-range/', "_blank");
                    }}>Shop Now
                    </button>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-12 col-lg-12">
                    <h1 className="shop-heading">Songs</h1>
                    <div className="row p-4">
                        <div className="col-12">
                            <h2 className="shop-heading text-center pt-5">coming soon</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;