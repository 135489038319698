import React, { useEffect } from 'react';
import ReactPixel from "react-facebook-pixel";

function Video() {
    useEffect(() => {
        document.title = 'TilleyTempo | Video';
    });

    ReactPixel.trackCustom( 'About', {Page:'Video'});

    return (
        <div className="row">
            <div className="col-12">
                <div className="video-section">
                    <span >
                        coming soon
                    </span>
                </div>
            </div>
        </div>
    );
}

export default Video;