import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import SiteLogo from "./logo/SiteLogo";
import siteLogoBlack from "../common/logo/site-logo.json";
import useSound from "use-sound";
import homeAudio from "../../assets/sounds/home_audio_gallery_contact_click.mp3";
import aboutAudio from "../../assets/sounds/about_video_shop_click.mp3";

function NavBar() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [playHome] = useSound(homeAudio);
  const [playAbout] = useSound(aboutAudio);
  const handleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <NavLink to="/" className="navbar-brand">
        <SiteLogo height={150} width={300} logoColor={siteLogoBlack} />
      </NavLink>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={handleNavCollapse}
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className={`collapse navbar-collapse ${isNavCollapsed ? "" : "show"}`}
        id="navbarTogglerDemo01"
      >
        <ul className="navbar-nav ml-auto mt-2 mt-lg-0 text-center">
          <li className="nav-item">
            <NavLink
              onClick={playHome}
              exact="true"
              to="/"
              className="nav-link"
              activeclassname="active"
            >
              <img
                src={require("../../assets/images/home_icon.png")}
                alt="Home"
              />
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              onClick={playAbout}
              to="/about"
              className="nav-link"
              activeclassname="active"
            >
              <img
                src={require("../../assets/images/about_icon.png")}
                alt="About"
              />
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              onClick={playHome}
              to="/music"
              className="nav-link"
              activeclassname="active"
            >
              <img
                src={require("../../assets/images/audio_icon.png")}
                alt="Music"
              />
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              onClick={playAbout}
              to="/video"
              className="nav-link"
              activeclassname="active"
            >
              <img
                src={require("../../assets/images/video_icon.png")}
                alt="Video"
              />
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              onClick={playHome}
              to="/gallery"
              className="nav-link"
              activeclassname="active"
            >
              <img
                src={require("../../assets/images/gallery_icon.png")}
                alt="Gallery"
              />
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              onClick={playAbout}
              to="/shop"
              className="nav-link"
              activeclassname="active"
            >
              <img
                src={require("../../assets/images/shop_icon.png")}
                alt="Shop"
              />
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              onClick={playHome}
              to="/contact"
              className="nav-link"
              activeclassname="active"
            >
              <img
                src={require("../../assets/images/contact_icon.png")}
                alt="Contact"
              />
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default NavBar;
