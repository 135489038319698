import React, { useState, useEffect } from "react";
import { Gallery } from "react-grid-gallery";
import ReactPixel from "react-facebook-pixel";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

var imagesPath = require.context(
  "../../assets/images/gallery",
  false,
  /.*\.(gif|jpe?g|png|webp|bmp|svg)$/
);

function GalleryList() {
  const [images, setImages] = useState([]);
  const [index, setIndex] = useState(-1);

  const currentImage = images[index];
  const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index); // Removed type annotations
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  useEffect(() => {
    document.title = "TilleyTempo | Gallery";
    imagesPath.keys().forEach((item) => {
      //   console.log(imagesPath(item));
      setImages((images) => [
        ...images,
        { src: imagesPath(item), thumbnail: imagesPath(item) },
      ]);
    });
  }, []);

  ReactPixel.trackCustom("About", { Page: "Gallery" });

  return (
    <div className="row">
      <div className="col-12">
        <Gallery
          images={images}
          enableImageSelection={false}
          onClick={handleClick}
        />
        {!!currentImage && (
          <Lightbox
            mainSrc={currentImage.src}
            imageTitle={currentImage.caption}
            mainSrcThumbnail={currentImage.src}
            nextSrc={nextImage.src}
            nextSrcThumbnail={nextImage.src}
            prevSrc={prevImage.src}
            prevSrcThumbnail={prevImage.src}
            onCloseRequest={handleClose}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}
      </div>
    </div>
  );
}

export default GalleryList;
